const IdentityVerificationTypes = {
  TYPE_ALL: 'all',
  TYPE_SELF_SHOWING: 'self-showing',
};

const IdentityVerificationOptions = [
  {
    label: 'Default to enabled for all showing types',
    value: IdentityVerificationTypes.TYPE_ALL,
  },
  {
    label: 'Default to enabled for self-showings only',
    value: IdentityVerificationTypes.TYPE_SELF_SHOWING,
  },
  {
    label: 'Always default to disabled',
    value: null,
  },
];

const IncomeVoucherTypes = {
  DEFAULT: null,
  DEDUCTIBLE: 'deductible',
  QUALIFIES: 'qualifies',
};

const IncomeVoucherOptions = [
  {
    label: 'Use the entire rent',
    value: IncomeVoucherTypes.DEFAULT,
  },
  {
    label: 'Use only the rent the prospect is responsible for',
    value: IncomeVoucherTypes.DEDUCTIBLE,
  },
  {
    label: 'Automatically qualify prospects with vouchers',
    value: IncomeVoucherTypes.QUALIFIES,
  },
];

const WebsiteChatTypes = {
  DEFAULT: null,
  LISTING_PAGE: 'listing-page',
  ALL_SITE: 'all-site',
};

const WebsiteChatOptions = [
  {
    label: 'Disabled',
    value: WebsiteChatTypes.DEFAULT,
  },
  {
    label: 'Enabled on listings page only',
    value: WebsiteChatTypes.LISTING_PAGE,
  },
  {
    label: 'Enabled on entire website',
    value: WebsiteChatTypes.ALL_SITE,
  },
];

const DefaultTrainingMaterials = [
  {
    question: 'What are your business hours?',
    response: '',
  },
  {
    question: 'Do you accept government subsidies, such as Section 8 Housing Choice Vouchers?',
    response: '',
  },
  {
    question: 'Do you allow pets?',
    response: '',
  },
  {
    question: 'Do you restrict any types of animals or breeds?',
    response: '',
  },
  {
    question: 'Do you have any special processes for service animals?',
    response: '',
  },
  {
    question: 'How do you handle security deposits?',
    response: '',
  },
  {
    question: 'Is the deposit refundable?',
    response: '',
  },
  {
    question: 'Do I pay the first month’s rent and last month’s rent with my security deposit?',
    response: '',
  },
  {
    question: 'Do you charge any fees outside the rent?',
    response: '',
  },
  {
    question: 'Do you require renter’s insurance?',
    response: '',
  },
  {
    question: 'Is there anything else you’d like us to know?',
    response: '',
  },
];

export {
  IdentityVerificationOptions,
  IdentityVerificationTypes,
  IncomeVoucherTypes,
  IncomeVoucherOptions,
  WebsiteChatTypes,
  WebsiteChatOptions,
  DefaultTrainingMaterials,
};
